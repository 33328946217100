import React, { useCallback, useEffect, useState } from 'react';
import {
	Grid,
	TextField,
	Button,
} from "@material-ui/core";
import searchIcon from '../../assets//img/Search.svg';
import { useStyles } from './styles';
import { useLazyQuery } from 'react-apollo';
import { GET_CUSTOMER_SALESFORCE } from './query';
import { Loader } from "../../components";
import DataTable from 'react-data-table-component';
import { useMutation } from 'react-apollo';
import { UNLINK_CUSTOMER } from './query';

export const SearchCustomerSalesforce = ({ onSelect, newMatchingFromLink, clearMatchingFromLink, newLinkToRemoveFromFranposUnlink, clearNewLinkToRemoveFromFranposUnlink, removeLinkInFranposSibling }) => {
	const classes = useStyles();
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [searchResponse, setSearchResponse] = useState([]);
	const [phone, setPhone] = useState('')
	const [email, setEmail] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [unlinkError, setUnlinkError] = useState('')
	const [phoneInputError, setPhoneInputError] = useState('')
	const [emailInputError, setEmailInputError] = useState('')
	const [firstNameInputError, setFirstNameInputError] = useState('')
	const [lastNameInputError, setLastNameInputError] = useState('')

	const functions = {
		onCompleted: (res) => {
			setLoading(false)
			setSearchResponse(res.contacts)
		},
		onError: () => {
			setError(true)
		},
		fetchPolicy: 'no-cache'
	}

	useEffect(() => {
		if (newMatchingFromLink.salesforceId) {
			const temp = [...searchResponse]

			temp.forEach((obj, idx) => {
				if (obj.Id === newMatchingFromLink.salesforceId) {
					temp[idx].Links = [newMatchingFromLink]
				}
			})

			setSearchResponse(temp)
			clearMatchingFromLink()
		}
	}, [newMatchingFromLink])

	useEffect(() => {
		if (
			newLinkToRemoveFromFranposUnlink.franposId,
			newLinkToRemoveFromFranposUnlink.salesforceId
		) {
			const temp = [...searchResponse]

			temp.forEach((obj, idx) => {
				if (obj.Id === newLinkToRemoveFromFranposUnlink.salesforceId) {
					temp[idx].Links = temp[idx].Links.filter((obj) => obj.Id !== newLinkToRemoveFromFranposUnlink.franposId)
				}
			})
			setSearchResponse(temp)

			clearNewLinkToRemoveFromFranposUnlink()
		}
	}, [newLinkToRemoveFromFranposUnlink])

	const [getCustomer] = useLazyQuery(GET_CUSTOMER_SALESFORCE, functions);

	const validateInputs = () => {
		setPhoneInputError('')
		setEmailInputError('')
		setFirstNameInputError('')
		setLastNameInputError('')

		const validateBy = {
			phone: {
				regex: /(\d{10})$/,
				note: 'ten digits'
			},
			email: {
				regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				note: 'azAZ09._%+- @ azAZ09.- . at least 2 character'
			},
			firstName: {
				regex: /^[A-Za-z]+$/,
				note: 'letters'
			},
			lastName: {
				regex: /^[A-Za-z]+$/,
				note: 'letters'
			}
		}

		function validateInput(str, regex) {
			const match = str.match(regex);
			return match ? true : false;
		}

		let phoneValidate = false
		if (phone) {
			const phoneDigits = phone.replace(/\D/g, "");
			phoneValidate = validateInput(phoneDigits, validateBy.phone.regex);
		} else if (phone === '') {
			phoneValidate = true
		}

		let emailValidate = false;
		if (email) {
			emailValidate = validateInput(email, validateBy.email.regex);
		} else if (email === '') {
			emailValidate = true
		}

		let firstNameValidate = false;
		if (firstName) {
			firstNameValidate = validateInput(firstName, validateBy.firstName.regex)
		} else if (firstName === '') {
			firstNameValidate = true
		}

		let lastNameValidate = false
		if (lastName) {
			lastNameValidate = validateInput(lastName, validateBy.lastName.regex)
		} else if (lastName === '') {
			lastNameValidate = true
		}

		if (!phoneValidate) {
			setPhoneInputError('Invalid')
		}

		if (!emailValidate) {
			setEmailInputError('Invalid')
		}

		if (!firstNameValidate) {
			setFirstNameInputError('Invalid')
		}

		if (!lastNameValidate) {
			setLastNameInputError('Invalid')
		}

		return phoneValidate && emailValidate && firstNameValidate && lastNameValidate

	}

	const doSearch = () => {
		if (
			phone !== ''
			|| email !== ''
			|| firstName !== ''
			|| lastName !== ''
		) {
			let validInputs = false
	
			validInputs = validateInputs()
			console.log('*********** validInputs  ', validInputs);
	
			if (validInputs) {
				setLoading(true)
				getCustomer({ variables: { firstName, lastName, email, phone } })
			}
		}
	}

	const formatPhoneNumber = (value) => {
		// Remove all non-numeric characters
		let cleaned = ('' + value).replace(/\D/g, '');

		// Limit to 10 digits
		cleaned = cleaned.substring(0, 10);

		// Format the number
		const match = cleaned.match(/^(\d{1,3})(\d{1,3})?(\d{1,4})?$/);
		if (match) {
			let formatted = '(' + match[1];
			if (match[2]) {
				formatted += ') ' + match[2];
			}
			if (match[3]) {
				formatted += '-' + match[3];
			}
			return formatted;
		}

		return value;
	};

	const [unlinkIds] = useMutation(UNLINK_CUSTOMER, {
		onCompleted: (data) => {
			setLoading(false)

			const temp = [...searchResponse]

			temp.forEach((row, idx) => {
				if (row.Id === data.unlinkCustomer.salesforceId) {
					temp[idx].Links = temp[idx].Links.filter((obj) => obj.Id !== data.unlinkCustomer.franposId)
				}
			})

			removeLinkInFranposSibling({
				franposId: data.unlinkCustomer.franposId,
				salesforceId: data.unlinkCustomer.salesforceId
			})

			setSearchResponse(temp)
		},
		onError: (err) => {
			throw err
		},
	});

	const unlinkCustomer = (item) => {
		if (
			item.Id
			&& item.salesforceId
		) {
			setLoading(true);

			unlinkIds({
				variables: {
					salesforceId: item.salesforceId,
					franposId: item.Id,
				}
			}).catch(() => {
				setUnlinkError('Error')
			})
		}
	}

	const renderTable = useCallback(() => {
		if (loading) {
			return <Loader />;
		}

		const columns = [
			{
				name: 'Id',
				selector: row => row.Id,
				sortable: true,
				reorder: false,
				grow: 2
			},
			{
				name: 'Phone',
				selector: row => row.Phone,
				sortable: true,
				reorder: false,
			},
			{
				name: 'Email',
				selector: row => row.Email,
				sortable: true,
				reorder: false,
				grow: 2
			},
			{
				name: 'FirstName',
				selector: row => row.FirstName,
				sortable: true,
				reorder: false,
			},
			{
				name: 'LastName',
				selector: row => row.LastName,
				sortable: true,
				reorder: false,
			},
			{
				name: '# of Links',
				selector: row => row.Links.length,
				sortable: true,
				reorder: false,
			},
			{
				name: 'Stage Link',
				selector: (row) => {
					return (
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								onSelect(row)
							}}
						>
							Stage
						</Button>
					)
				},
				sortable: false,
				reorder: false,
			}
		]

		const ExpandedComponent = ({ data }) => {
			return (
				<table style={{ width: '100%', borderCollapse: 'collapse' }}>
					<thead>
						<tr>
							<th style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>ID</th>
							<th style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>Phone</th>
							<th style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>Email</th>
							<th style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>FirstName</th>
							<th style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>LastName</th>
							<th style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}></th>
						</tr>
					</thead>
					<tbody>
						{data.Links.map((item, index) => (
							<tr key={index}>
								<td style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>{item.Id}</td>
								<td style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>{item.Phone}</td>
								<td style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>{item.Email}</td>
								<td style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>{item.FirstName}</td>
								<td style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>{item.LastName}</td>
								<td style={{ border: '1px solid #CBCBCB', padding: '8px', fontSize: '13px', fontWeight: '400' }}>
									<Button
										variant="contained"
										color="primary"
										onClick={() => {
											unlinkCustomer(item)
										}}
									>
										Unlink
									</Button>
									<span>{unlinkError}</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)
		};

		const rowPreDisabled = row => {
			return row.Links.length === 0
		}

		return (
			<>
				<DataTable
					columns={columns}
					data={searchResponse}
					expandableRows
					expandableRowsComponent={ExpandedComponent}
					expandableRowDisabled={rowPreDisabled}
					pagination
				/>
			</>
		)
	}, [loading, searchResponse]);

	const renderError = () => <p style={{ color: 'red' }}>Salesforce Error</p>

	return (
		<div>
			<Grid className={classes.marginBottom15} item>
				Search for Salesforce customer
			</Grid>
			{/* Salesforce Search */}
			<Grid item>
				<Grid container direction="row" spacing={1}>
					<Grid item>
						<Grid container direction="column" spacing={1}>
							<Grid item>
								<TextField
									label="Phone"
									variant="outlined"
									size="small"
									value={phone}
									onChange={(e) => setPhone(formatPhoneNumber(e.target.value))}
								/>
							</Grid>
							<Grid item>
								<span style={{ color: 'red' }}>{phoneInputError}</span>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container direction="column" spacing={1}>
							<Grid item>
								<TextField
									label="Email"
									variant="outlined"
									size="small"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Grid>
							<Grid item>
								<span style={{ color: 'red' }}>{emailInputError}</span>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container direction="column" spacing={1}>
							<Grid item>
								<TextField
									label="First Name"
									variant="outlined"
									size="small"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</Grid>
							<Grid item>
								<span style={{ color: 'red' }}>{firstNameInputError}</span>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Grid container direction="column" spacing={1}>
							<Grid item>
								<TextField
									label="Last Name"
									variant="outlined"
									size="small"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</Grid>
							<Grid item>
								<span style={{ color: 'red' }}>{lastNameInputError}</span>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							className={`${classes.searchButton}`}
							onClick={() => {
								doSearch()
							}}
						>
							<img src={searchIcon} alt="search-icon" />
						</Button>
					</Grid>
				</Grid>
			</Grid>
			{error ? renderError() : renderTable()}
		</div>
	);
};
