import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
	button: {
		color: '#FFFFFF',
		paddingRight: 0,
		fontWeight: 'bold',
		fontSize: '0.625rem',
		lineHeight: '0.75rem',
		textAlign: 'right',
		letterSpacing: '0.11em',
		textTransform: 'uppercase'
	},
    header: {
		boxShadow: 'unset'
	},
	headerMobile: {
		boxShadow: 'unset',
	},
	wrapper: {
		minHeight: '2rem',
		padding: 0
	},
	storeHeader: {
		position: 'absolute',
		height: '6rem',
		left: 0,
		right: 0,
		top: '2rem',
		background: '#FFFFFF',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
	},
	imageContainer: {
		padding: '1.25rem 0rem'
	},
	storeHeading: {
		fontWeight: 'bold',
		fontSize: '1.125rem',
		lineHeight: '1.313rem',
		display: 'flex',
		alignItems: 'center',
		letterSpacing: '0.04em',
		color: COLORS.headings,
		[theme.breakpoints.down('xs')]: {
			fontWeight: 'bold',
			fontSize: '0.8rem',
		}
	},
	storeHeaderMobile: {
		padding: '0rem 0.4rem 0rem 0.4rem',
		position: 'absolute',
		height: '6rem',
		left: '0%',
		right: '-0.02%',
		top: '2rem',
		background: '#FFFFFF',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
	},
	wrapperMobile: {
		minHeight: '2rem',
		padding: 0,
	},
	headerTablet: {
		boxShadow: 'unset',
		padding: '0rem 3rem 0rem 3rem'
	},
	storeHeaderTablet: {
		padding: '0rem 3rem 0rem 3rem',
		position: 'absolute',
		height: '6rem',
		left: '0%',
		right: '-0.02%',
		top: '2rem',
		background: '#FFFFFF',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
	},
}));

export { useStyles };
