import React, { useState, useCallback, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Grid,
  TextField,
  Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import searchIcon from '../../assets//img/Search.svg';
import { getComparator, stableSort } from '../../utils/DataTableUtils';
import { MaterialDialog } from '../MaterialDialog/MaterialDialog';
import { FINALIZE_ORDER, GET_ORDER_VIEW } from '../../containers/OrderView/query';
import { useFeedback } from '../../hooks';
import { Loader } from '../Loader/Loader';
import { GET_FULLFILMENT_ORDERS } from '../../containers/Dashboard/query';
import { useStyles } from './styles';

const headCells = [
  { id: 'ordId', disablePadding: false, label: 'Order Number' },
  { id: 'name', disablePadding: false, label: 'Name' },
  { id: 'orderType', disablePadding: false, label: 'Order Type' },
  { id: 'orderStatus', disablePadding: false, label: 'Order Status' },
  { id: 'placedOn', disablePadding: false, label: 'Order Placed On' },
  { id: 'button', disablePadding: false, label: '' }
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span className={classes.tableHeading}>{headCell.label}</span>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const DataTable = (props) => { 
  const { rows, data, searchValue, setSearchValue, currentTab,refreshPage} = props;
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('placedOn');
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState(data.title != 'SEARCH ALL HISTORICAL ORDERS' && rows );
  const [searchNow, setSearchNow]= useState({status: false});
  const [openFinal, setOpenFinal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [oderToFinal, setOrderToFinal] = useState(null);

  const { setFeedback } = useFeedback()
  const rowsPerPage = 40;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(()=>{
    if(data.title === 'SEARCH ALL HISTORICAL ORDERS'){
      if(!searchValue){
        setFilteredRows('')
      } else {
        setFilteredRows(rows);
      }
    }else{
      if(searchValue){
        const smallVal = searchValue.toLowerCase().trim();
        const filteredData = filteredRows && filteredRows.filter(item => {
          return Object.keys(item).some(key => item[key].toString().toLowerCase().includes(smallVal));
        });
        setFilteredRows(filteredData);
      }else{
        ActiveRowsCount();
      }
    }
  },[searchNow])

  const ActiveRowsCount = () => {
    var array = [];
    rows.map((row) => {
      const today = new Date();
      const before = new Date(row.placedOn)
      var Difference_In_Time = today.getTime() - before.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      var finalResult = Math.round(Difference_In_Days)

      if(finalResult < 8) {
        array.push(row)
      } else if(row.orderStatus !== 'Canceled' && row.orderStatus !== 'Fulfilled') {
        array.push(row)
      }
    })
    setFilteredRows(array)
  }

  const [finalizeOrder, { loading: finalizeLoading }] = useMutation(FINALIZE_ORDER,
    {
      onCompleted() {
        setOpenFinal(false);
        setFeedback({
          display: true,
          message: 'Order finalized successfully.',
          type: 'success'
        });
      },
      onError({ graphQLErrors }) {
        const error = graphQLErrors[0]?.extensions?.validationErrors?.pin;
        setOpenFinal(false);

        setFeedback({
          display: true,
          message: `${graphQLErrors[0].message}: ${error}`,
          type: 'error'
        });
      }
    });
  const handleFinalizeOrder = useCallback((empPin, id) => {
    const input = {
      id: id,
      pin: empPin
    };

    setOpenFinal(false);
    finalizeOrder({
      variables: { input },
      update(cache, { data }) {
        //updating current updated order in cache
        cache.writeQuery({
          query: GET_ORDER_VIEW,
          variables: { id: id },
          data: {
            fulfillmentOrder: data.fulfillOrder.FulfillmentOrder
          }
        });
        //updating current updated order in cache from all orders
        const { fulfillmentOrders } = cache.readQuery({ query: GET_FULLFILMENT_ORDERS });
        const newFulfillmentOrders = fulfillmentOrders.map((item) => {
          if (item.Id === id) {
            return {
              ...item,
              Status: data.fulfillOrder.FulfillmentOrder.Status
            };
          }
          refreshPage();
          return item;
        });
        cache.writeQuery({
          query: GET_FULLFILMENT_ORDERS,
          data: {
            fulfillmentOrders: [...newFulfillmentOrders]
          }
        });
      }
    }).catch((e) => {
      setFeedback({
        display: true,
        message: e,
        type: 'error'
      })
    })
  }, []);

  const Dialog = useCallback(() => {
    return (
        <MaterialDialog
            open={openFinal}
            handleCloseDialoge={() => setOpenFinal(false)}
            dialogType={'process'}
            finalizeOrder={handleFinalizeOrder}
            ordId={oderToFinal}
        />
    )
  }, [openFinal, oderToFinal])

  const TableBodyData = () => {
    return (
      <TableBody>
        {filteredRows && stableSort(filteredRows, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
            const today = new Date();
            const before = new Date(row.placedOn)
            var Difference_In_Time = today.getTime() - before.getTime();
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            var finalResult = Math.round(Difference_In_Days)
if(checked==true){
  return (
    <TableRow
      tabIndex={-1}
      key={row.id}
      className={classes.rowStyles}
    >
      <TableCell>
        <Link to={`order/${row.id}`} style={{ textDecoration: 'none' }}><span className={`${classes.dataStyles} ${classes.ordID}`}>{row.ordId}</span></Link>
      </TableCell>
      <TableCell><span className={classes.dataStyles}>{row.name}</span></TableCell>
      <TableCell><span className={classes.dataStyles}>{row.orderType}</span></TableCell>
      <TableCell><span className={classes.dataStyles}>{row.orderStatus}</span></TableCell>
      <TableCell><span className={classes.dataStyles}>{row.placedOn}</span></TableCell>
      {row.orderStatus === 'Processed' && row.orderType === 'Curbside'?
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            className={classes.pickupButton}
            onClick={() => {
              setOpenFinal(true)
              setOrderToFinal(row.id);
            }}
          >
            Mark as Picked Up
          </Button>
        </TableCell> :
        <TableCell />
      }
    </TableRow>
  )

}else{
  if(finalResult < 8){
    return (
      <TableRow
        tabIndex={-1}
        key={row.id}
        className={classes.rowStyles}
      >
        <TableCell>
          <Link to={`order/${row.id}`} style={{ textDecoration: 'none' }}><span className={`${classes.dataStyles} ${classes.ordID}`}>{row.ordId}</span></Link>
        </TableCell>
        <TableCell><span className={classes.dataStyles}>{row.name}</span></TableCell>
        <TableCell><span className={classes.dataStyles}>{row.orderType}</span></TableCell>
        <TableCell><span className={classes.dataStyles}>{row.orderStatus}</span></TableCell>
        <TableCell><span className={classes.dataStyles}>{row.placedOn}</span></TableCell>
        {row.orderStatus === 'Processed' && row.orderType === 'Curbside'?
          <TableCell>
            <Button
              variant="contained"
              color="primary"
              className={classes.pickupButton}
              onClick={() => {
                setOpenFinal(true)
                setOrderToFinal(row.id);
              }}
            >
              Mark as Picked Up
            </Button>
          </TableCell> :
          <TableCell />
        }
      </TableRow>
    )} else if(row.orderStatus !== 'Canceled' && row.orderStatus !== 'Fulfilled'){
      return (
        <TableRow
          tabIndex={-1}
          key={row.id}
          className={classes.rowStyles}
        >
          <TableCell>
            <Link to={`order/${row.id}`} style={{ textDecoration: 'none' }}><span className={`${classes.dataStyles} ${classes.ordID}`}>{row.ordId}</span></Link>
          </TableCell>
          <TableCell><span className={classes.dataStyles}>{row.name}</span></TableCell>
          <TableCell><span className={classes.dataStyles}>{row.orderType}</span></TableCell>
          <TableCell><span className={classes.dataStyles}>{row.orderStatus}</span></TableCell>
          <TableCell><span className={classes.dataStyles}>{row.placedOn}</span></TableCell>
          {row.orderStatus === 'Processed' && row.orderType === 'Curbside'?
            <TableCell>
              <Button
                variant="contained"
                color="primary"
                className={classes.pickupButton}
                onClick={() => {
                  setOpenFinal(true)
                  setOrderToFinal(row.id);
                }}
              >
                Mark as Picked Up
              </Button>
            </TableCell> :
            <TableCell />
          }
        </TableRow>
      )
    
  }else{
    if(currentTab == 5)
      {
        return (
          <TableRow
            tabIndex={-1}
            key={row.id}
            className={classes.rowStyles}
          >
            <TableCell>
              <Link to={`order/${row.id}`} style={{ textDecoration: 'none' }}><span className={`${classes.dataStyles} ${classes.ordID}`}>{row.ordId}</span></Link>
            </TableCell>
            <TableCell><span className={classes.dataStyles}>{row.name}</span></TableCell>
            <TableCell><span className={classes.dataStyles}>{row.orderType}</span></TableCell>
            <TableCell><span className={classes.dataStyles}>{row.orderStatus}</span></TableCell>
            <TableCell><span className={classes.dataStyles}>{row.placedOn}</span></TableCell>
            {row.orderStatus === 'Processed' && row.orderType === 'Curbside'?
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.pickupButton}
                  onClick={() => {
                    setOpenFinal(true)
                    setOrderToFinal(row.id);
                  }}
                >
                  Mark as Picked Up
                </Button>
              </TableCell> :
              <TableCell />
            }
          </TableRow>
        )      
      }     
  }
}
})}
      </TableBody>
    );
  };

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <Grid container direction="row">
          <Grid item md={7} xs={12}>
            <Grid container direction="row" className={classes.tableHeaderPagination}>
              <TablePagination
                component="div"
                count={filteredRows ? filteredRows.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
                labelDisplayedRows={({ from, to, count }) => {
                  return <span>{`Showing ${from}-${to} of ${count} active orders`}</span>;
                }}
                classes={{ spacer: classes.hideSpacer, toolbar: classes.toolbarPagination }}
              />
            </Grid>
          </Grid>
          <Grid item md={5} xs={12}>
            <Grid container direction="row" className={classes.tableHeaderSearch} >
              <TextField
                placeholder="Search"
                variant="outlined"
                onKeyPress={(event) => {
                  if (event.key!=='Enter') { return; }
                  if (currentTab === 5) {
                    props.doSearch()
                    return;
                  }
                  setSearchNow({status: true})
                }}
                classes={{ root: classes.searchField }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  if(currentTab==5){
                    if(e.target.value !=null&& e.target.value != undefined){
                      setChecked(true)
                      if(e.target.value===''){
                        setSearchNow({status: true})
                      }
                    }else{
                      setChecked(false)
                      if(e.target.value===''){
                        setSearchNow({status: true})
                      }
                    }
                  }else{
                    if(e.target.value===''){
                      setSearchNow({status: true})
                    }
                  }
                }}
              />
              <Button
                variant="contained"
                color="primary"
                className={`${classes.pickupButton} ${classes.searchButton}`}
                onClick={()=>{
                  if (currentTab === 5) {
                    props.doSearch()
                    return;
                  }
                  setSearchNow({status: true})
                }}
              >
                <img src={searchIcon} alt="search-icon" />
              </Button>
            </Grid>
          </Grid >
        </Grid>
        <TableContainer>
          <Table className={classes.table} >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows && filteredRows.length}
            />
            <TableBodyData />
          </Table>
        </TableContainer>
        <Dialog />
      </Paper>
      {finalizeLoading && <Loader />}
    </div>
  );
};
