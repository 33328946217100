import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

export const Footer = () => {
  const classes = useStyles();
  return (
      <div className={classes.root}>
        <Typography>Copyright ©  2024 Mudbay</Typography>
      </div>
  );
};
