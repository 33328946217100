import React, { useState } from 'react';
import {
	Button,
	Grid,
	// Divider,
	TextField,
	CircularProgress
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { SearchCustomerSalesforce } from './SearchCustomerSalesforce';
import { SearchCustomerFranpos } from './SearchCustomerFranpos';
import { useStyles } from './styles';
import { useFeedback } from '../../hooks';
import { useMutation } from 'react-apollo';
import { LINK_CUSTOMER } from './query';

// Many salesforce ids can link to 1 franpos id
// 1 salesforce id cannot be linked to multiple franpos id

// to ensure this, set salesforce id in table to be unique
// Insert on duplicate key update

// sf fp

// 1   1
// 2   1
// 3   1
// 4   2
// 6   3

export const LinkCustomer = () => {
	const { setFeedback } = useFeedback();
	const emptySelect = {
		Id: null,
		FirstName: null,
		LastName: null,
		Email: null,
		Phone: null,
	}

	const emptyUnlink = {
		franposId: null,
		salesforceId: null
	}

	const [selectedIdSalesforce, setSelectedIdSalesforce] = useState(emptySelect)
	const [selectedIdFranpos, setSelectedIdFranpos] = useState(emptySelect)
	const [linkStatus, setLinkStatus] = useState('')
	const [linkMessage, setLinkMessage] = useState('')
	const [loading, setLoading] = useState(false)

	const [newMatchSalesforce, setNewMatchSalesforce] = useState(emptySelect)
	const [newMatchFranpos, setNewMatchFranpos] = useState(emptySelect)

	const [removeLinkInSalesforceSiblingRow, setRemoveLinkInSalesforceSiblingRow] = useState(emptyUnlink)
	const [removeLinkInFranposSiblingRow, setRemoveLinkInFranposSiblingRow] = useState(emptyUnlink)

	const classes = useStyles()

	const handleSelectSalesforce = (selection) => {
		setLinkStatus('')
		setLinkMessage('')

		setSelectedIdSalesforce(selection);
	}

	const handleSelectFranpos = (selection) => {
		if (selection) {
			setSelectedIdFranpos({
				Id: selection.Id,
				FirstName: selection.FirstName,
				LastName: selection.LastName,
				Email: selection.Email,
				Phone: selection.CellPhone,
			});
			setLinkMessage('')
		} else {
			setSelectedIdFranpos(emptySelect)
		}
	}

	const [linkIds] = useMutation(LINK_CUSTOMER, {
		onCompleted: () => {
			setLoading(false)

			const dataToAddToLinksSalesforce = {
				Id: selectedIdFranpos.Id,
				FirstName: selectedIdFranpos.FirstName,
				LastName: selectedIdFranpos.LastName,
				Phone: selectedIdFranpos.Phone,
				Email: selectedIdFranpos.Email,
				salesforceId: selectedIdSalesforce.Id
			}
			setNewMatchSalesforce(dataToAddToLinksSalesforce)

			const dataToAddToLinksFranpos = {
				Id: selectedIdSalesforce.Id,
				FirstName: selectedIdSalesforce.FirstName,
				LastName: selectedIdSalesforce.LastName,
				Phone: selectedIdSalesforce.Phone,
				Email: selectedIdSalesforce.Email,
				franposId: selectedIdFranpos.Id
			}
			setNewMatchFranpos(dataToAddToLinksFranpos)

		},
		onError: (err) => {
			throw err
		},
	});

	const linkCustomer = () => {
		if (
			selectedIdSalesforce.Id
			&& selectedIdFranpos.Id
		) {
			setLoading(true);

			linkIds({
				variables: {
					salesforceId: selectedIdSalesforce.Id,
					franposId: `${selectedIdFranpos.Id}`,
				},
				update(_cache, { data }) {
					setLinkStatus(data.linkCustomer.status)

					setTimeout(() => {
						setLinkStatus('')
					}, 9000)
				}
			}).catch((e) => {
				setFeedback({
					display: true,
					message: e,
					type: 'error'
				})
			})
		}
	}

	const clearStaging = () => {
		setSelectedIdSalesforce(emptySelect);
		setSelectedIdFranpos(emptySelect)
	}

	return (
		<div>
			<Grid container direction="column" spacing={10}>
				<Grid item>
					<SearchCustomerSalesforce
						onSelect={handleSelectSalesforce}
						newMatchingFromLink={newMatchSalesforce}
						clearMatchingFromLink={() => setNewMatchSalesforce(emptySelect)}
						removeLinkInFranposSibling={(data) => setRemoveLinkInFranposSiblingRow(data)}
						newLinkToRemoveFromFranposUnlink={removeLinkInSalesforceSiblingRow}
						clearNewLinkToRemoveFromFranposUnlink={() => setRemoveLinkInSalesforceSiblingRow(emptyUnlink)}
					></SearchCustomerSalesforce>
				</Grid>

				<Grid item>
					<SearchCustomerFranpos
						onSelect={handleSelectFranpos}
						newMatchingFromLink={newMatchFranpos}
						clearMatchingFromLink={() => setNewMatchFranpos(emptySelect)}
						removeLinkInSalesforceSibling={(data) => setRemoveLinkInSalesforceSiblingRow(data)}
						newLinkToRemoveFromSalesforceUnlink={removeLinkInFranposSiblingRow}
						clearNewLinkToRemoveFromSalesforceUnlink={() => setRemoveLinkInFranposSiblingRow(emptyUnlink)}
					></SearchCustomerFranpos>
				</Grid>

				<Grid className={`${classes.marginTop10}`} item>
					<Grid className={classes.marginBottom15} item>
						Link Customer
					</Grid>
					<Grid container direction="row" spacing={1}>
						<Grid item>
							<Grid container direction="column">
								<Grid item>
									<TextField
										label="Salesforce"
										variant="outlined"
										size="small"
										value={`${selectedIdSalesforce.Id}`}
										disabled
										className={classes.disabledTextField}
									/>
								</Grid>
								<Grid item>
									<TextField
										variant="outlined"
										size="small"
										value={`${selectedIdSalesforce.FirstName} ${selectedIdSalesforce.LastName}`}
										disabled
										className={classes.disabledTextField}
									/>
								</Grid>
								<Grid item>
									<TextField
										variant="outlined"
										size="small"
										value={`${selectedIdSalesforce.Email}`}
										disabled
										className={classes.disabledTextField}
									/>
								</Grid>
								<Grid item>
									<TextField
										variant="outlined"
										size="small"
										value={`${selectedIdSalesforce.Phone}`}
										disabled
										className={classes.disabledTextField}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item className={classes.marginTop10}>
							<span>&#8594;</span>
						</Grid>
						<Grid item>
							<Grid container direction="column">
								<TextField
									label="Franpos"
									variant="outlined"
									size="small"
									value={`${selectedIdFranpos.Id}`}
									disabled
									className={classes.disabledTextField}
								/>
								<TextField
									variant="outlined"
									size="small"
									value={`${selectedIdFranpos.FirstName} ${selectedIdFranpos.LastName}`}
									disabled
									className={classes.disabledTextField}
								/>
								<TextField
									variant="outlined"
									size="small"
									value={`${selectedIdFranpos.Email}`}
									disabled
									className={classes.disabledTextField}
								/>
								<TextField
									variant="outlined"
									size="small"
									value={`${selectedIdFranpos.Phone}`}
									disabled
									className={classes.disabledTextField}
								/>
							</Grid>
						</Grid>
						<Grid item>
							<Grid container direction="column" spacing={1}>
								<Grid item>
									<Button
										variant="contained"
										color="primary"
										className={`${classes.searchButton}`}
										onClick={linkCustomer}
										disabled={loading}
									>{loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : 'Link'}
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										color="primary"
										className={`${classes.searchButton}`}
										onClick={clearStaging}
									>Clear
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<p>{linkMessage}</p>
						</Grid>
						<Grid item>
							{
								linkStatus === '' ? <></> :
									linkStatus ?
										<Alert severity="success" className={classes.marginRight0}></Alert>
										: <Alert severity="error" className={classes.marginRight0}></Alert>
							}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
