import { gql } from 'apollo-boost';

export const GET_CUSTOMER_SALESFORCE = gql`
	query contacts($firstName: String, $lastName: String, $email: String, $phone: String) {
		contacts(firstName: $firstName, lastName: $lastName, email: $email, phone: $phone) {
			Id
			FirstName
			LastName
			Email
			Phone
			Links {
				Id
				FirstName
				LastName
				Email
				Phone
				salesforceId
			}
		}
	}
`;

export const GET_CUSTOMER_FRANPOS = gql`
	query contactsFranpos($firstName: String, $lastName: String, $email: String, $phone: String) {
		contactsFranpos(firstName: $firstName, lastName: $lastName, email: $email, phone: $phone) {
			Id
			FirstName
			LastName
			Email
			Phone
			Links {
				Id
				FirstName
				LastName
				Email
				Phone
				franposId
			}
		}
	}
`

export const LINK_CUSTOMER = gql`
	mutation linkCustomer($salesforceId: String, $franposId: String) {
		linkCustomer(salesforceId: $salesforceId, franposId: $franposId) {
			salesforceId
			franposId
			status
		}
	}
`;

export const UNLINK_CUSTOMER = gql`
	mutation unlinkCustomer($salesforceId: String, $franposId: String) {
		unlinkCustomer(salesforceId: $salesforceId, franposId: $franposId) {
			salesforceId
			franposId
			status
		}
	}
`;