import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: '1rem 0 1rem 0',
	},
	backButton: {
		textDecoration: 'none',
		fontSize: '1rem',
		lineHeight: '1.188rem',
		letterSpacing: '0.04rem',
		textTransform: 'unset'
	},
	searchButton: {
		// margin: '0.5rem 0rem 1rem 0rem'
	},
	searchField: {
		'& .MuiOutlinedInput-root': {
			height: '2.688rem',
			marginTop: '0.5rem'
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: COLORS.buttonsColor
	},
	table: {
		minWidth: 750,
	},
	disabledTextField: { // Make TextField Id grey
		'& .MuiInputBase-root.Mui-disabled': {
			backgroundColor: 'lightgray', // Set your custom background color here
		},
		'& .Mui-disabled': {
			color: 'black', // Set your custom text color here
		},
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
			borderColor: 'gray', // Set your custom border color here
		},
	},
	marginTop10: {
		marginTop: '10px'
	},
	marginBottom15: {
		marginBottom: '15px'
	},
	marginRight0: {
		'& .MuiAlert-icon': {
			marginRight: '0px'
		}
	},
	buttonProgress: {
		color: '#4caf50',
	}
}));

export { useStyles };
